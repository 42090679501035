function setCookie (cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function eraseCookie (cname) {
    document.cookie = cname+"=; Max-Age=-999999";
}
function getFormData ($form) {
    var unindexed_array = $form.serializeArray();
    var indexed_array = {};

    $.map(unindexed_array, function(n, i) {
        indexed_array[n['name']] = n['value'];
    });

    return indexed_array;
}

function postJSON (url, data) {
    return $.ajax({
        url: url,
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(data),
    });
}

function getCookie(name) {
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    // because unescape has been deprecated, replaced with decodeURI
    //return unescape(dc.substring(begin + prefix.length, end));
    return decodeURI(dc.substring(begin + prefix.length, end));
}

function changeLoginState (res, state, $login) {
    $login.removeClass(state);
    $login.find('input[name="state"]').val(res.state);
    $login.addClass(res.state);
    res.state == 'login' ? $login.find('input[name="password"]').focus() : '';
    if (res.message) {
        $login.find('.error').html(res.message);
    }

    if (res.token) {
        setCookie('Authorization', res.token, 7);
        localStorage.setItem('user', JSON.stringify(res.user));
        location.href = '/famille';
    }
}

$(document).ready(function() {

    // widget login

    var $login = $('section#login.log-form');
    $login.addClass('check_email');

    $login.on('submit', 'form', (e) => {
        e.preventDefault();
        var state = $login.find('input[name="state"]').val();
        var data = getFormData($login.find('form'));

        postJSON('/login/'+state, data).then(function (res) {
            changeLoginState(res && res.result || res, state, $login);
        });
    });

    $login.on('click', '.forgot-password', (e) => {
        postJSON('/login/forgot', {email: $login.find('input[name="email"]').val()}).then(function (res) {
            changeLoginState(res && res.result || res, 'forgot login', $login);
        });
    });

    changeLoginState({ state: 'check_email' }, 'check_email', $login);

});

module.exports = class Login {};